import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep3---pradip-khakhar-on-becoming-a-product-manager"
    }}>{`Ep3 - Pradip Khakhar: On becoming a product manager`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/876619621&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep3-pradip-khakhar/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/rVOpbVPBT4c">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`In this episode, we talk with Pradip Khakhar, a Product Manager. We talk about his journey from studying electrical electronic engineering to becoming a product manager.`}</p>
    <p>{`We talk about building products, Pradip shares his knowledge about the importance of creating value for customers and users. He says that it's not about building, it's about why you build, who you build for and what value you want to give.`}</p>
    <p>{` Pradip also shares some great tips on how you can start networking with social media and many more useful tips for anyone wanting to become a developer.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/pradip-khakhar.jpg" alt="Pradip Khakhar" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Pradip Khakhar</p>
        <p>Product Manager</p>
        <p>
            <a href="https://theproductangle.com/"> Website</a> | <a href="https://www.youtube.com/channel/UC6frKMWXqVBR82MxSoOKwXg/"> YouTube</a> | <a href="https://twitter.com/pradipcloud"> Twitter</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "design"
    }}>{`Design`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.co.uk/gp/product/0321125215/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=0321125215&linkCode=as2&tag=fabiorosado-21&linkId=7c05484d8284a24ec65d49b25030ef85"
        }}>{`Book: Domain-Driven Design: Tackling Complexity in the Heart of Software`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=pMuiVlnGqjk"
        }}>{`Youtube: What is DDD - Eric Evans - DDD Europe 2019 `}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getmakerlog.com/log"
        }}>{`GetMakerlog - Maker community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://theproductangle.com/"
        }}>{`The Product Angle`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      